import axios from 'axios';
import moment from 'moment';

export const sendInvoices = async ({ reportsData, usdRate, payPeriod }) => {
  const getWorkdaysInMonth = () => {
    const month = moment(payPeriod, 'MMM YYYY').format('MM');
    let workdays = 0;
    let date = new Date(payPeriod.split(' ')[1], month - 1, 1);

    while (date.getMonth() === month - 1) {
      let day = date.getDay();
      if (day !== 0 && day !== 6) workdays++;
      date.setDate(date.getDate() + 1);
    }

    return workdays;
  };

  const calcMonthCapacity = (assigneeCap) =>
    getWorkdaysInMonth() *
    (assigneeCap === 'Part-time+' ? 6 : assigneeCap === 'Part-time' ? 4 : 8);

  const getTotalHrs = (projects) =>
    projects.map((p) => Object.values(p)[0]).reduce((a, b) => a + b, 0);

  const calcSalary = (data) =>
    data.assignee.wage
      ? `(${getTotalHrs(data.projects)}h of ${data.assignee.wage}$/m = ${(
          (getTotalHrs(data.projects) * data.assignee.wage) /
          calcMonthCapacity(data.assignee.availability)
        ).toFixed(2)}$) x ${usdRate}₴ (NBU) = ${(
          ((getTotalHrs(data.projects) * data.assignee.wage) /
            calcMonthCapacity(data.assignee.availability)) *
          usdRate
        ).toFixed(2)}₴`
      : `(${getTotalHrs(data.projects)}h x ${data.assignee.rate}$/h = ${(
          getTotalHrs(data.projects) * data.assignee.rate
        ).toFixed(2)}$) x ${usdRate}₴ (NBU) = ${(
          getTotalHrs(data.projects) *
          data.assignee.rate *
          usdRate
        ).toFixed(2)}₴`;

  reportsData.forEach((r) => {
    r.receivers.forEach(async (receiver) => {
      const messageData = {
        channel: receiver,
        preview: 'Salary report is available!',
        blocks: [
          {
            type: 'section',
            text: {
              type: 'mrkdwn',
              text: `*${r.assignee.firstName} ${
                r.assignee.lastName
              } - salary report for ${payPeriod}*\n\n${r.projects
                .map(
                  (p) =>
                    `${Object.entries(p)[0][0]} - ${
                      Object.entries(p)[0][1]
                    } hr(s)`
                )
                .join('\n')}\n\n*Total:* ${calcSalary(r)}`,
            },
          },
        ],
      };

      try {
        await axios.post(
          `${
            process.env.VUE_APP_NODE_ENV === 'production'
              ? `https://${
                  process.env.VUE_APP_NODE_ENV === 'staging'
                    ? 'jupi-plus.onrender'
                    : 'www.jupiplus'
                }.com`
              : 'http://localhost:3000'
          }/api/publish-message`,
          messageData
        );
      } catch (err) {
        return err.response;
      }
    });
  });
};
