<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="filteredData"
      item-key="id"
      group-by="country"
      sort-by="firstName"
      :header-props="{ sortIcon: null }"
      :footer-props="{
        'items-per-page-options': [-1],
      }"
      @click:row="$emit('call-edit-form', $event)"
    >
      <!-- grouping override -->
      <template v-slot:[`group.header`]="{ group, headers, toggle, isOpen }">
        <td
          class="pa-0 group-header-row"
          :colspan="headers.length"
          @click="toggle"
        >
          <v-btn
            :ref="group"
            :data-open="isOpen"
            large
            icon
            class="ml-1 vertical-middle"
            @click.stop="toggle"
          >
            <v-icon v-if="isOpen">mdi-chevron-down</v-icon>
            <v-icon v-else>mdi-chevron-right</v-icon>
          </v-btn>
          <v-img
            max-width="48"
            width="48"
            :height="getFlag(group) === noCountry ? 48 : 36"
            :src="getFlag(group)"
            class="d-inline-flex mx-2 vertical-middle"
            :alt="`${group}_flag`"
          ></v-img>
          <h2 class="mr-4 font-weight-bold d-inline vertical-middle">
            {{ group }}
          </h2>
          <v-chip class="font-weight-bold white vertical-middle elevation-2">
            {{ getActiveOfAvailable(group) }}
          </v-chip>
        </td>
      </template>

      <!-- Name cell override -->
      <template v-slot:[`item.id`]="{ item }">
        <span>{{ `${item.firstName} ${item.lastName}` }}</span>
      </template>

      <!-- Start Date cell override -->
      <!-- <template v-slot:[`item.startDate`]="{ value }">
        <span>{{ formatDateForTableRow(value) }}</span>
      </template> -->

      <!-- Main Skill cell override -->
      <template v-slot:[`item.skill`]="{ item }">
        <span>{{ getMainSkill(item) }}</span>
      </template>

      <!-- General Expertise cell override -->
      <template v-slot:[`item.generalExpertise`]="{ item }">
        <span>{{ getGeneralExpertise(item) }}</span>
      </template>

      <!-- LinkedIn Profile cell override -->
      <template v-slot:[`item.personalDetails[linkedIn]`]="{ value }">
        <v-btn
          icon
          large
          color="blue darken-2"
          :disabled="!value"
          @click.stop="openLinkedInProfile(value)"
          ><v-icon>mdi-linkedin</v-icon></v-btn
        >
      </template>

      <!-- Time Off cell override -->
      <template v-slot:[`item.adminDetails[timeOff]`]="{ value }">
        <span>{{
          value
            ? `From ${formatDateForTableRow(
                value.startDate
              )} to ${formatDateForTableRow(value.endDate)}`
            : ''
        }}</span>
      </template>

      <!-- Slack Staff cell override -->
      <template v-slot:[`item.adminDetails[slackIdStaff]`]="{ value }">
        <v-btn
          fab
          x-small
          outlined
          elevation="1"
          color="accent"
          :href="`slack://user?team=${cueStaff}&id=${value}`"
          :disabled="!value"
          @click.stop
          ><v-icon>mdi-slack</v-icon></v-btn
        >
      </template>
    </v-data-table>
  </div>
</template>

<script>
// libraries
import _ from 'lodash';
import Fuse from 'fuse.js';
// internal
import { customBreakpoint, timeUnits } from '@/utils/mixins';
import { ISO_COUNTRIES } from '@/utils/constants';
import { toTitleCase } from '@/utils/dbUtils';
import noCountry from '@/assets/logo.png';

export default {
  name: 'StaffTable',
  props: {
    tableData: {
      type: Array,
      required: false,
    },
    filterStartDate: {
      type: Array,
      required: false,
    },
    filterSearch: {
      type: String,
      required: false,
    },
    filterLanguage: {
      type: String,
      required: true,
    },
    filterActiveStaff: {
      type: Boolean,
      required: true,
    },
    filterStatus: {
      type: Array,
      required: true,
    },
    filterCountry: {
      type: String,
      required: true,
    },
    filterSkill: {
      type: Array,
      required: true,
    },
    filterExpertise: {
      type: Array,
      required: true,
    },
    filterLeadEditors: {
      type: Boolean,
      required: true,
    },
    filterPCs: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      noCountry,
      headers: [
        { text: 'Name', value: 'id' },
        { text: 'Start Date', value: 'startDate' },
        {
          text: 'Native Language',
          value: 'personalDetails[language1][language]',
        },
        { text: 'Main Skill', value: 'skill' },
        { text: 'Primary Expertise', value: 'generalExpertise' },
        { text: 'LinkedIn Profile', value: 'personalDetails[linkedIn]' },
        { text: 'Time Off', value: 'adminDetails[timeOff]' },
        { text: 'Slack Staff', value: 'adminDetails[slackIdStaff]' },
      ],
    };
  },
  mixins: [customBreakpoint, timeUnits],
  mounted() {
    this.toggleAll();
    this.getTotalActiveOfAvailable();
  },
  computed: {
    tableFieldValues() {
      return this.tableData.length ? Object.keys(this.tableData[0]) : [];
    },
    filteredData() {
      let dateFilteredData = [...this.tableData];

      // status filtered
      // const filterMap = ['Available', 'Unavailable'];

      // const withStatus = this.filterStatus.map((el) => filterMap[el]);

      // dateFilteredData = this.tableData.filter((el) => {
      //   return withStatus.includes(el.adminDetails.status);
      // });

      if (this.filterActiveStaff) {
        dateFilteredData = dateFilteredData.filter(
          (el) => el.adminDetails.activeStaff
        );
      }

      if (this.filterStartDate.length) {
        const datesRange = [],
          startDate = this.$moment(this.filterStartDate[0]),
          endDate = this.$moment(
            this.filterStartDate[1]
              ? this.filterStartDate[1]
              : this.filterStartDate[0]
          );
        for (
          let dateVar = new Date(startDate);
          dateVar <= endDate;
          dateVar.setDate(dateVar.getDate() + 1)
        ) {
          datesRange.push(this.$moment(new Date(dateVar)).format('MM-DD-YYYY'));
        }
        dateFilteredData = dateFilteredData.filter((el) => {
          return datesRange.includes(
            this.$moment.unix(el.adminDetails.startDate).format('MM-DD-YYYY')
          );
        });
      }

      if (this.filterLanguage) {
        dateFilteredData = dateFilteredData.filter(
          (el) => el.personalDetails.language1.language === this.filterLanguage
        );
      }

      if (this.filterCountry) {
        dateFilteredData = dateFilteredData.filter(
          (el) => el.hrDetails.country === this.filterCountry
        );
      }

      // filter skills
      // const skills = [
      //   'copywriting',
      //   'copyediting',
      //   'translation',
      //   'editingTranslations',
      //   'curation',
      //   'design',
      //   'metadata',
      //   'projectManagement',
      // ];
      // const skillsList = this.filterSkill.map((el) => skills[el]);
      // dateFilteredData = dateFilteredData.filter((el) =>
      //   skillsList.every((item) => el.skill[item] !== 'N/A' && el.skill[item])
      // );

      // filter expertise
      // const expertise = [
      //   'music',
      //   'filmOrTv',
      //   'book',
      //   'games',
      //   'podcast',
      //   'mobileApp',
      //   'lifestyle',
      //   'sports',
      // ];
      // const expertiseList = this.filterExpertise.map((el) => expertise[el]);
      // dateFilteredData = dateFilteredData.filter((el) =>
      //   expertiseList.every(
      //     (item) =>
      //       el.generalExpertise[item].years !== 'N/A' &&
      //       el.generalExpertise[item].years
      //   )
      // );

      if (this.filterLeadEditors) {
        dateFilteredData = dateFilteredData.filter(
          (el) => el.adminDetails.leadEditor
        );
      }

      if (this.filterPCs) {
        dateFilteredData = dateFilteredData.filter(
          (el) => el.user.userRole === 'MANAGER'
        );
      }

      const searchOptions = {
        includeScore: true,
        threshold: 0,
        ignoreLocation: true,
        keys: this.tableFieldValues,
      };

      if (this.filterSearch) {
        const fuse = new Fuse(dateFilteredData, searchOptions);
        dateFilteredData = fuse.search(this.filterSearch).map((el) => el.item);
      }

      return dateFilteredData;
    },
    cueStaff() {
      // return workspaces.find((el) => el.name === 'Cue Staff').id;
    },
  },
  watch: {
    filteredData(newVal) {
      this.getTotalActiveOfAvailable(newVal);
    },
  },
  methods: {
    toggleAll() {
      Object.keys(this.$refs).forEach((k) => {
        this.$refs[k].$el?.click();
      });
    },
    getFlag(country) {
      const isoCountry = Object.keys(ISO_COUNTRIES).find((item) =>
        item.includes(country)
      );
      return isoCountry
        ? `https://flagcdn.com/192x144/${ISO_COUNTRIES[
            isoCountry
          ].toLowerCase()}.png`
        : noCountry;
    },
    getTotalActiveOfAvailable(newData) {
      const data = newData ? newData : this.filteredData;
      const available = data.filter((staff) => !staff.endDate);
      const active = available.filter((staff) => !staff.endDate);
      const countries = [
        ...new Set(
          data.map((el) => {
            return el.country;
          })
        ),
      ];
      const total = `${active.length} Active | ${available.length} Available Staff Members in ${countries.length} Countries`;
      this.$emit('refresh-total-info', total);
    },
    getActiveOfAvailable(country) {
      const available = this.filteredData.filter(
        (staff) => !staff.endDate && staff.country === country
      );
      const active = available.filter(
        (staff) => !staff.endDate && staff.country === country
      );
      return `${active.length} Active | ${available.length} Available`;
    },
    formatDateForTableRow(date) {
      return date ? this.$moment.unix(date).format('MM-DD-YYYY') : '';
    },
    getMainSkill(item) {
      // const mainSkill =
      //   Object.entries(item.skill).find((el) => el[1] === '>10') ??
      //   Object.entries(item.skill).find((el) => el[1] === '5-10') ??
      //   Object.entries(item.skill).find((el) => el[1] === '2-5') ??
      //   Object.entries(item.skill).find((el) => el[1] === '<2');
      // return mainSkill ? toTitleCase(mainSkill[0]) : '';
    },
    getGeneralExpertise(item) {
      // const expertiseList = [
      //     { key: 'music', title: 'Music' },
      //     { key: 'film', title: 'Film & TV' },
      //     { key: 'book', title: 'Books' },
      //     { key: 'mobileAndVideoGames', title: 'Mobile/Video Games' },
      //     { key: 'podcast', title: 'Podcasts' },
      //     { key: 'mobileApps', title: 'Mobile Apps' },
      //     { key: 'lifestyle', title: 'Lifestyle' },
      //     { key: 'sports', title: 'Sports' },
      //   ].map((el) => {
      //     return {
      //       expertise: el.title,
      //       experience: item.generalExpertise[el.key].years,
      //     };
      //   }),
      //   generalExpertise =
      //     expertiseList.find((el) => el.experience === '>10') ??
      //     expertiseList.find((el) => el.experience === '5-10') ??
      //     expertiseList.find((el) => el.experience === '2-5') ??
      //     expertiseList.find((el) => el.experience === '<2');
      // return generalExpertise
      //   ? `${generalExpertise.expertise} (${generalExpertise.experience} years)`
      //   : '';
    },
    openLinkedInProfile(value) {
      const splitLink = value.split('/');
      const profile = splitLink.at(-1) ? splitLink.at(-1) : splitLink.at(-2);
      window.open(`https://www.linkedin.com/in/${profile}`, '_blank');
    },
  },
};
</script>

<style scoped lang="scss">
.group-header-row {
  background-color: #ececec !important;
  color: #003a35 !important;
}
.vertical-middle {
  vertical-align: middle;
}

::v-deep {
  tbody > tr {
    cursor: pointer;
  }
  .v-data-table.laptop .v-data-table__wrapper table {
    & tbody > tr > td {
      padding: 0 8px;
    }
    & .v-data-table-header tr > th {
      padding: 0 8px;
    }
  }
  .v-data-table.laptopSmaller .v-data-table__wrapper table {
    & tbody > tr > td {
      padding: 0 4px;
    }
    & .v-data-table-header tr > th {
      padding: 0 4px;
    }
  }
}
</style>
